<template>
  <div class="accordion-item">
    <div
      class="accordion-item__header"
      :class="{'accordion-item__header--expanded' : expandedProxy}"
      @click="onToggle">
      <h3>
        {{ title }}
      </h3>
      <img
        v-if="expandedProxy"
        alt="Collapse"
        src="@/assets/svg/minus.svg"
        width="16">
      <img
        v-else
        alt="Expand"
        src="@/assets/svg/plus.svg"
        width="16">
    </div>

    <p
      v-show="expandedProxy"
      class="accordion-item__content">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expandedProxy: this.expanded
    }
  },
  watch: {
    expanded (newVal) {
      this.expandedProxy = newVal
    }
  },
  methods: {
    onToggle () {
      this.expandedProxy = !this.expandedProxy
      this.$emit('toggle', {
        expanded: this.expandedProxy
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.accordion-item {
  &:first-of-type {
    border-top: 1px solid $grey-200;
  }

  border-bottom: 1px solid $grey-200;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 12px 0;

    h3 {
      color: $core-teal-500;
      font-size: 16px;
      font-family: "Roboto", Arial, sans-serif;
      letter-spacing: 0.5px;
      font-weight: normal;
    }
  }

  &__content {
    color: $core-black;
    margin: 0;
    padding-bottom: 24px;
  }
}
</style>
