<template>
  <div class="faq-wrapper">
    <PageBanner
      background="alternative"
      heading="Frågor och svar" />
    <div class="container faq">
      <LoadingSpinner v-if="loading">
        Laddar...
      </LoadingSpinner>

      <div
        v-else
        class="row">
        <div class="col-lg-7 col-12">
          <Accordion
            v-for="(accordion, accordionIndex) in content.items"
            :key="accordionIndex"
            class="faq-accordion">
            <h3 class="faq-accordion__title">
              {{ accordion.fields.title }}
            </h3>

            <template
              v-for="(accordionItem, accordionItemIndex) in accordion.fields.item">
              <AccordionItem
                :key="accordionItemIndex"
                classs="faq-accordion__item"
                :title="accordionItem.fields.question"
                :expanded="false">
                <RichTextRenderer :document="accordionItem.fields.answer" />
              </AccordionItem>
            </template>
          </Accordion>
        </div>

        <div class="col-lg-4 offset-lg-1">
          <SideCard
            title="Saknas svar?"
            description="Har du frågor eller synpunkter är du välkommen att kontakta oss."
            class="faq-sidecard">
            <template v-slot:button>
              <MainButton
                label="Kontakta oss"
                :expanded="true"
                @click="goToContactUs" />
            </template>
          </SideCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapState
} from 'vuex'

import Accordion from '@/components/accordion/Accordion'
import AccordionItem from '@/components/accordion/AccordionItem'
import LoadingSpinner from '@/components/LoadingSpinner'
import PageBanner from '@/components/views/PageBanner'
import MainButton from '@/components/MainButton'
import SideCard from '@/components/SideCard'

import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import { RouteName } from '@/router/router-constants'

export default {
  components: {
    SideCard,
    MainButton,
    LoadingSpinner,
    AccordionItem,
    Accordion,
    PageBanner,
    RichTextRenderer
  },
  computed: {
    ...mapState('Faq', [
      'loading',
      'exception',
      'content'
    ])
  },
  mounted () {
    this.loadContent()
  },
  methods: {
    ...mapActions('Faq', ['loadContent']),
    goToContactUs () {
      this.$router.push({ name: RouteName.public.Contact })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.faq {
  margin-top: 50px;
  &-accordion {
    margin-bottom: 40px;

    &-title {
      margin-bottom: 24px;
    }
  }

  &-sidecard {
    margin-bottom: 40px;
  }
}
</style>
